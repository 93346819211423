import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import createHocName from '../../services/create-hoc-name';
import {
  ComponentsContext,
  ComponentsContextValue,
} from './components-context';

export type WithComponents = ComponentsContextValue;

function withComponents<T extends WithComponents = WithComponents>(
  WrappedComponent: React.ComponentType<T>,
) {
  const WithComponents = (props: T) => (
    <ComponentsContext.Consumer>
      {(components) => <WrappedComponent {...components} {...props} />}
    </ComponentsContext.Consumer>
  );

  WithComponents.displayName = createHocName(
    'WithComponents',
    WrappedComponent,
  );

  hoistNonReactStatics(WithComponents, WrappedComponent);

  return WithComponents;
}

export default withComponents;
